import React from 'react';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as Styled from './styles';

export interface SkillsFrontmatter {
    title: string;
    subtitle: string;
    skills: [
        {
            title: string;
            icon: string;
            description: string;
        }
    ];
}

interface SkillsProps {
    data: {
        frontmatter: SkillsFrontmatter;
    };
}

const Skills: React.FC<SkillsProps> = ({ data }) => {
    const { frontmatter } = data;

    const {
        allFile: { edges },
    } = useStaticQuery<{ allFile: { edges: [any] } }>(graphql`
        {
            allFile(filter: { sourceInstanceName: { eq: "images-logos" } }) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `);

    const sectionTitle = {
        title: frontmatter.title,
        subtitle: frontmatter.subtitle,
    };
    const { skills } = frontmatter;

    return (
        <Container>
            <TitleSection center title={sectionTitle.title} subtitle={sectionTitle.subtitle} />
            <Styled.Skills>
                {skills.map((item, index) => {
                    const { title, icon, description } = item;
                    const edge = edges.find(
                        ({ node }) => `${node.name}.${node.extension}` === icon
                    );

                    const image = edge.node.childImageSharp.gatsbyImageData;

                    return (
                        <Styled.Skill key={index}>
                            <Styled.LogoTitle>
                                <GatsbyImage alt="technology" objectFit="contain" image={image} />
                                <h3 className="title">{title}</h3>
                            </Styled.LogoTitle>
                            <span className="skill-description">{description}</span>
                        </Styled.Skill>
                    );
                })}
            </Styled.Skills>
        </Container>
    );
};

export default Skills;
