import React from 'react';

import InfoBlock from 'components/ui/InfoBlock';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import { IconProps } from 'components/ui/Icon';

import * as Styled from './styles';

export interface ServicesFrontmatter {
    title: string;
    subtitle: string;
    services: [
        {
            title: string;
            icon: IconProps;
            description: string;
        }
    ];
}

interface ServicesProps {
    data: {
        frontmatter: ServicesFrontmatter;
    };
}

const Services: React.FC<ServicesProps> = ({ data }) => {
    const { frontmatter } = data;
    const sectionTitle = {
        title: frontmatter.title,
        subtitle: frontmatter.subtitle,
    };
    const { services } = frontmatter;

    return (
        <Container>
            <TitleSection center title={sectionTitle.title} subtitle={sectionTitle.subtitle} />
            <Styled.Services>
                {services.map((item, index) => {
                    const { title, icon, description } = item;

                    return (
                        <Styled.ServiceItem key={index}>
                            <InfoBlock icon={icon} title={title} content={description} />
                        </Styled.ServiceItem>
                    );
                })}
            </Styled.Services>
        </Container>
    );
};

export default Services;
