import React from 'react';

import Banner from 'components/ui/Banner';

export interface HeroFrontmatter {
    title: string;
    subtitle: string;
    content: string;
    linkTo: string;
    linkText: string;
    socials: [
        {
            href: string;
            icon: string;
        }
    ];
}

interface SectionHeroBannerProps {
    data: {
        frontmatter: HeroFrontmatter;
    };
}

const HeroBanner: React.FC<SectionHeroBannerProps> = ({ data }) => {
    const { frontmatter } = data;

    return (
        <Banner
            title={frontmatter.title}
            subtitle={frontmatter.subtitle}
            content={frontmatter.content}
            linkTo={frontmatter.linkTo}
            linkText={frontmatter.linkText}
            socials={frontmatter.socials}
        />
    );
};

export default HeroBanner;
