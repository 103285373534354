import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Skills = styled.div`
    ${tw`flex flex-wrap w-full `};
`;

export const Skill = styled.div`
    ${tw`p-4 m-2 bg-secondary flex flex-col rounded-lg`};

    width: 100%;
    @media (min-width: 640px) {
        width: calc(33.333333% - 1rem);
    }

    & .skill-description {
        ${tw`text-gray-500`}
        margin-top: 8px;
        font-size: 15px;
    }
`;

export const LogoTitle = styled.div`
    ${tw`flex items-center`};

    & img {
        width: 35px;
        margin-right: 8px;
    }

    & .title {
        ${tw`text-white font-semibold`}
    }
`;
