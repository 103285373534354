import React from 'react';

import Container from 'components/ui/Container';
import Button from 'components/ui/Button';
import TitleSection from 'components/ui/TitleSection';

import Icon, { IconProps } from 'components/ui/Icon';

import LocalizedLink from 'components/LocalizedLink';
import * as Styled from './styles';

interface Props {
    title: string;
    subtitle?: string;
    content: React.ReactNode;
    linkTo: string;
    linkText: string;
    socials: [
        {
            href: string;
            icon: string;
        }
    ];
}

const Banner: React.FC<Props> = ({ title, subtitle, content, linkTo, linkText, socials }) => (
    <Styled.Banner>
        <Container>
            <TitleSection title={title} subtitle={subtitle} onDarkBg />
            <Styled.Content>{content}</Styled.Content>
            <LocalizedLink to={linkTo} style={{ alignSelf: 'center' }}>
                <Button primary>{linkText}</Button>
            </LocalizedLink>
            <Styled.Socials>
                {socials.map(({ href, icon }) => {
                    const splitted = icon.split('-');
                    const ic =
                        splitted.length === 1
                            ? (splitted[0] as IconProps)
                            : (splitted as IconProps);

                    return (
                        <Styled.Social
                            key={href}
                            href={href}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Icon icon={ic} />
                        </Styled.Social>
                    );
                })}
            </Styled.Socials>
        </Container>
    </Styled.Banner>
);

export default Banner;
