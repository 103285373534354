import React from 'react';
import Icon from '../Icon';

import * as Styled from './styles';

interface Props extends Styled.StyledProps {
    children: React.ReactNode;
}

const Button: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    primary,
    block,
    children,
    onClick,
    loading,
    disabled,
}) => (
    <Styled.Button
        primary={primary}
        block={block}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        disabled={disabled}
        onClick={onClick}
    >
        {children}
        {loading && <Icon spin icon="circle-notch" className="spinner" fontSize="inherit" />}
    </Styled.Button>
);

export default Button;
