import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import SEO, { SeoFrontmatter } from 'components/SEO';
import HeroBanner, { HeroFrontmatter } from 'components/HeroBanner';
import Services, { ServicesFrontmatter } from 'components/Services';
import Skills, { SkillsFrontmatter } from 'components/Skills';
import locales from 'constants/locales';
// import Testimonials from 'components/Testimonials';

interface Props {
    pageContext: { locale: keyof typeof locales };
    data: {
        hero: { frontmatter: HeroFrontmatter };
        services: { frontmatter: ServicesFrontmatter };
        skills: { frontmatter: SkillsFrontmatter };
        seo: {
            frontmatter: SeoFrontmatter;
        };
    };
}

const IndexPage: React.FC<Props> = ({
    pageContext: { locale },
    data: { hero, services, skills, seo },
}) => {
    return (
        <Layout locale={locale}>
            <SEO
                path=""
                title={seo.frontmatter.title}
                description={seo.frontmatter.description}
                locale={seo.frontmatter.locale}
            />
            <HeroBanner data={hero} />
            <Services data={services} />
            <hr />
            <Skills data={skills} />
            {/* <Testimonials /> */}
        </Layout>
    );
};

export const query = graphql`
    query IndexQuery($locale: String!) {
        seo: markdownRemark(
            frontmatter: { category: { eq: "seo-about" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                description
                locale
            }
        }
        hero: markdownRemark(
            frontmatter: { category: { eq: "hero section" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                subtitle
                content
                linkTo
                linkText
                socials {
                    href
                    icon
                }
            }
        }
        services: markdownRemark(
            frontmatter: { category: { eq: "services" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                subtitle
                services {
                    title
                    icon
                    description
                }
            }
        }
        skills: markdownRemark(
            frontmatter: { category: { eq: "skills" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                subtitle
                skills {
                    title
                    icon
                    description
                }
            }
        }
    }
`;

export default IndexPage;
