import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Banner = styled.section`
    ${tw`bg-secondary border-b border-indigo-100 pt-2 sm:pt-12`};
`;

export const Content = styled.p`
    ${tw`mb-8 text-gray-200 whitespace-pre-line text-lg`};
`;

export const Socials = styled.div`
    ${tw`flex flex-wrap items-center w-full mt-5 ml-1 sm:w-auto sm:ml-6 sm:mt-0`}
`;

export const Social = styled.a`
    ${tw`mx-4 text-4xl text-gray-100 hover:text-primary`}
`;
