import styled from 'styled-components';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';

export interface StyledProps {
    primary?: boolean;
    block?: boolean;
    loading?: boolean;
}

export const Button = motion.custom(styled.button<StyledProps>`
    outline: none !important;
    ${tw`py-2 px-8 rounded-full border border-primary text-white`};

    ${({ primary }) => (primary ? tw`bg-primary` : tw`text-indigo-600`)};

    ${({ block }) => block && tw`w-full`};

    ${({ disabled }) => disabled && tw`pointer-events-none`}

    & .spinner {
        ${tw`ml-3`}
    }
`);
